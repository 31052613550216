import styled from 'styled-components';

export default styled.div`
  form {
    max-width: 500px;
  }
  .contact-form-disclaimer {
    opacity: 0.7;
  }
`;
