import React, { FC, useState } from 'react';
import { MainScreen } from 'components';
import { Button, TextField } from '@mui/material';
import { RegisterOptions, useForm } from 'react-hook-form';
import { delay, getTimeOfDay, isEmptyObject } from 'utils/helpers';
import { CheckCircle } from '@mui/icons-material';
import ContactPageWrapper from './page.styled';

/**
 * Validator for validating a subject field.
 * @type {RegisterOptions}
 */
const subjectValidator: RegisterOptions = {
  required: true,
  minLength: 3,
  maxLength: 998,
};

/**
 * Validator for validating an email field.
 * @type {RegisterOptions}
 */
const emailValidator: RegisterOptions = {
  required: true,
  pattern: /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,9}$/,
};

/**
 * Validator for validating a message field.
 * @type {RegisterOptions}
 */
const messageValidator: RegisterOptions = {
  required: true,
  minLength: 50,
};

interface ContactPageProps {}

interface ContactForm {
  subject: string;
  email: string;
  message: string;
}

const ContactPage: FC<ContactPageProps> = () => {
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ContactForm>({
    delayError: 1000,
    mode: 'onChange',
  });

  const onSubmit = async (data: ContactForm) => {
    const randomNumber = Math.floor(Math.random() * 501) + 1000;
    const mailtoLink = `mailto:hello@goodridge.io?subject=${data.subject}&body=${data.message}`;

    try {
      setLoading(true);
      await delay(randomNumber);
      window.open(mailtoLink, '_blank', 'rel=noopener noreferrer');
      setSubmitted(true);
    } finally {
      setLoading(false);
    }
  };

  const isFormInvalid: boolean = !isEmptyObject(errors) || loading;

  const currentTimeOfDay = getTimeOfDay();
  const placeholderTexts = {
    morning: 'Good morning Sam...',
    afternoon: 'Good afternoon Sam...',
    night: 'Hello Sam...',
  };

  return (
    <ContactPageWrapper data-test-contact-page-wrapper>
      <MainScreen
        title="Contact"
        description="Want to connect? Drop me a message 👋 and share your contact details. Let's chat over a virtual coffee!"
      >
        {submitted ? (
          <div className="mt9 ui-display-flex ui-items-center ui-justify-center ui-color-success">
            <span className="pr2">Redirect Success</span>
            <CheckCircle />
          </div>
        ) : (
          <>
            <form
              className="p6 mb4 mt5 ui-display-flex ui-flex-column ui-radius-common ui-bg-color-card"
              method="get"
              encType="text/plain"
              onSubmit={handleSubmit(onSubmit)}
            >
              <TextField
                {...register('subject', subjectValidator)}
                variant="filled"
                label="Subject"
                placeholder="Hello from Cairo!"
                autoFocus
                error={Boolean(errors.subject)}
                helperText="Invalid subject field."
              />
              <div className="mt5">
                <TextField
                  {...register('email', emailValidator)}
                  variant="filled"
                  label="Email"
                  placeholder="example@example.com"
                  error={Boolean(errors.email)}
                  helperText="Invalid email field."
                />
              </div>
              <div className="mt5 mb9">
                <TextField
                  {...register('message', messageValidator)}
                  variant="filled"
                  label="Message"
                  placeholder={placeholderTexts[currentTimeOfDay]}
                  multiline
                  minRows={5}
                  maxRows={10}
                  error={Boolean(errors.message)}
                  helperText="Message should consist of at least 50 characters."
                />
              </div>
              <Button
                className="ui-height-xjumbo ui-font-weight-thick"
                type="submit"
                variant="text"
                disabled={isFormInvalid}
              >
                {loading ? 'Loading...' : 'Send via Email'}
              </Button>
            </form>
            <span className="ui-font-xsmall contact-form-disclaimer">
              Upon clicking &#34;Send via Email,&#34; your default email application will launch
              pre-filled with data submitted.
            </span>
          </>
        )}
      </MainScreen>
    </ContactPageWrapper>
  );
};

export default ContactPage;
