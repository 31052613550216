export type ModalType = 'theme';

/**
 * Used to provide single source of truth for common route names,
 * and provide an easier path for migration and distinguishing
 * actual routes from strings that may coincidentally named like them.
 */
export const ROUTE_NAME_MAP = Object.freeze({
  HOME_INDEX: '/',
  ARCHIVE_INDEX: '/archive',
  CONTACT_INDEX: '/contact',
  modal: (modal?: ModalType) => `/i/${modal || ':modal/*'}`,
});

/**
 * Array of available modal types.
 */
export const MODAL_TYPE_ARRAY: ModalType[] = ['theme'];
