import { AppSize, ThemeColor, ThemeMode } from 'constants/application-theme';

/**
 * Application theme action type.
 */
export type ApplicationThemeAction = 'mode' | 'color' | 'size' | 'isSystemDefault';

/**
 * Application theme enum.
 */
export enum ApplicationThemeEnum {
  MODE = 'mode',
  COLOR = 'color',
  SIZE = 'size',
  IS_SYSTEM_DEFAULT = 'isSystemDefault',
}

/**
 * Application theme state interface.
 */
export interface ApplicationThemeState {
  mode: ThemeMode;
  color: ThemeColor;
  size: AppSize;
  isSystemDefault: boolean;
}

/**
 * Action creator for application theme actions.
 * @template T - The type of the action.
 * @param {T} type - The type of the action.
 * @returns {ActionCreator<T>} The action creator function.
 */
type ActionCreator<T extends ApplicationThemeAction> = (payload: any) => { type: T; payload: any };

/**
 * Object containing action creators for application theme actions.
 */
export const setApplicationTheme: Record<
  ApplicationThemeAction,
  ActionCreator<ApplicationThemeAction>
> = {
  /**
   * Action creator for setting the theme mode.
   * @param mode - The mode to set.
   * @returns Action object with type and mode.
   */
  mode: (mode: ThemeMode) => ({ type: 'mode', payload: mode }),
  /**
   * Action creator for setting the theme color.
   * @param color - The color to set.
   * @returns Action object with type and color.
   */
  color: (color: ThemeColor) => ({ type: 'color', payload: color }),
  /**
   * Action creator for setting the app size.
   * @param size - The size to set.
   * @returns Action object with type and size.
   */
  size: (size: string) => ({ type: 'size', payload: size }),
  /**
   * Action creator for setting is System default.
   * @param isDefault - The boolean to set.
   * @returns Action object with type and size.
   */
  isSystemDefault: (isDefault: boolean) => ({ type: 'isSystemDefault', payload: isDefault }),
};
