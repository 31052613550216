import { ThemeProvider as StyledThemeProvider, createGlobalStyle } from 'styled-components';
import {
  ApplicationTheme,
  FONT_SIZES,
  GLOBAL_COLORS,
  ICON_SIZES,
  THEME_COLORS,
  THEME_MODE_COLORS,
} from 'constants/application-theme';
import React, { ReactNode } from 'react';
import { useApplicationTheme } from 'hooks';
import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material';

/**
 * Global css styling
 */
const GlobalStyle = createGlobalStyle<{}>`
  :root {
    /* Color variables */
    --gw-color-theme: ${(p) => p.theme.currentThemeColor};

    --gw-color-mosiac: ${THEME_COLORS.mosiac};
    --gw-color-verdant: ${THEME_COLORS.verdant};
    --gw-color-ignis: ${THEME_COLORS.ignis};
    --gw-color-champion: ${THEME_COLORS.champion};
    --gw-color-amethyst: ${THEME_COLORS.amethyst};
    --gw-color-fiery: ${THEME_COLORS.fiery};

    --gw-color-error: ${GLOBAL_COLORS.error};
    --gw-color-red: ${GLOBAL_COLORS.red};
    --gw-color-warn: ${GLOBAL_COLORS.warn};
    --gw-color-success: ${GLOBAL_COLORS.success};
    --gw-color-gold: ${GLOBAL_COLORS.gold};
    --gw-color-white: ${GLOBAL_COLORS.white};
    --gw-color-black: ${GLOBAL_COLORS.black};

    --gw-color-alpha: ${(p) => p.theme.alpha};
    --gw-color-bravo: ${(p) => p.theme.bravo};
    --gw-color-medium: ${(p) => p.theme.medium};
    --gw-color-charlie: ${(p) => p.theme.charlie};
    --gw-color-card: ${(p) => p.theme.card};

    --gw-color-modal: rgba(0, 0, 0, 0.5);

    /* Font size variables */
    --gw-font-xsmall: ${(p) => p.theme.font.xsmall};
    --gw-font-small: ${(p) => p.theme.font.small};
    --gw-font-normal: ${(p) => p.theme.font.normal};
    --gw-font-large: ${(p) => p.theme.font.large};
    --gw-font-xlarge: ${(p) => p.theme.font.xlarge};
    --gw-font-jumbo: ${(p) => p.theme.font.jumbo};
    --gw-font-xjumbo: ${(p) => p.theme.font.xjumbo};

    /* Icon size variables */
    --gw-icon-xsmall: ${(p) => p.theme.icon.xsmall};
    --gw-icon-small: ${(p) => p.theme.icon.small};
    --gw-icon-normal: ${(p) => p.theme.icon.normal};
    --gw-icon-large: ${(p) => p.theme.icon.large};
    --gw-icon-xlarge: ${(p) => p.theme.icon.xlarge};
    --gw-icon-jumbo: ${(p) => p.theme.icon.jumbo};
    --gw-icon-xjumbo: ${(p) => p.theme.icon.xjumbo};

    /* Spacing variables */
    --gw-item-size-0: 0px;
    --gw-item-size-1: 4px;
    --gw-item-size-2: 8px;
    --gw-item-size-3: 10px;
    --gw-item-size-4: 14px;
    --gw-item-size-5: 18px;
    --gw-item-size-6: 22px;
    --gw-item-size-7: 24px;
    --gw-item-size-8: 28px;
    --gw-item-size-9: 34px;

    --gw-opacity-disabled: 0.7;
  }
  body {
    background-color: var(--gw-color-alpha);
    color: var(--gw-color-charlie);
    font-size: var(--gw-font-normal);
  }
  ::-webkit-scrollbar-track { 
    background-color: var(--gw-color-alpha);
  }
  ::-webkit-scrollbar-thumb { 
    background-color: var(--gw-color-bravo);
    &:hover {
      background-color: var(--gw-color-theme);
    }
  }
  ::selection { 
    background-color: var(--gw-color-theme);
  }
  /* ATOMICS */
  /* Font classes */
  .ui-font-xsmall { font-size: var(--gw-font-xsmall); }
  .ui-font-small { font-size: var(--gw-font-small); }
  .ui-font-normal { font-size: var(--gw-font-normal); }
  .ui-font-large { font-size: var(--gw-font-large); }
  .ui-font-xlarge { font-size: var(--gw-font-xlarge); }
  .ui-font-jumbo { font-size: var(--gw-font-jumbo); }
  .ui-font-xjumbo { font-size: var(--gw-font-xjumbo); }

  /* Icon classes */
  .ui-height-xsmall { height: var(--gw-icon-xsmall); }
  .ui-height-small { height: var(--gw-icon-small); }
  .ui-height-normal { height: var(--gw-icon-normal); }
  .ui-height-large { height: var(--gw-icon-large); }
  .ui-height-xlarge { height: var(--gw-icon-xlarge); }
  .ui-height-jumbo { height: var(--gw-icon-jumbo); }
  .ui-height-xjumbo { height: var(--gw-icon-xjumbo); }

  .ui-width-xsmall { width: var(--gw-icon-xsmall); }
  .ui-width-small { width: var(--gw-icon-small); }
  .ui-width-normal { width: var(--gw-icon-normal); }
  .ui-width-large { width: var(--gw-icon-large); }
  .ui-width-xlarge { width: var(--gw-icon-xlarge); }
  .ui-width-jumbo { width: var(--gw-icon-jumbo); }
  .ui-width-xjumbo { width: var(--gw-icon-xjumbo); }

  /* Color classes */
  .ui-color-theme { color: var(--gw-color-theme); }
  .ui-bg-color-theme { background-color: var(--gw-color-theme); }
  .ui-border-color-theme { border-color: var(--gw-color-theme); }

  .ui-color-mosiac { color: var(--gw-color-mosiac); }
  .ui-bg-color-mosiac { background-color: var(--gw-color-mosiac); }
  .ui-border-color-mosiac { border-color: var(--gw-color-mosiac); }

  .ui-color-verdant { color: var(--gw-color-verdant); }
  .ui-bg-color-verdant { background-color: var(--gw-color-verdant); }
  .ui-border-color-verdant { border-color: var(--gw-color-verdant); }

  .ui-color-ignis { color: var(--gw-color-ignis); }
  .ui-bg-color-ignis { background-color: var(--gw-color-ignis); }
  .ui-border-color-ignis { border-color: var(--gw-color-ignis); }

  .ui-color-champion { color: var(--gw-color-champion); }
  .ui-bg-color-champion { background-color: var(--gw-color-champion); }
  .ui-border-color-champion { border-color: var(--gw-color-champion); }

  .ui-color-amethyst { color: var(--gw-color-amethyst); }
  .ui-bg-color-amethyst { background-color: var(--gw-color-amethyst); }
  .ui-border-color-amethyst { border-color: var(--gw-color-amethyst); }

  .ui-color-fiery { color: var(--gw-color-fiery); }
  .ui-bg-color-fiery { background-color: var(--gw-color-fiery); }
  .ui-border-color-fiery { border-color: var(--gw-color-fiery); }

  .ui-color-error { color: var(--gw-color-error); }
  .ui-bg-color-error { background-color: var(--gw-color-error); }
  .ui-border-color-error { border-color: var(--gw-color-error); }

  .ui-color-red { color: var(--gw-color-red); }
  .ui-bg-color-red { background-color: var(--gw-color-red); }
  .ui-border-color-red { border-color: var(--gw-color-red); }

  .ui-color-warn { color: var(--gw-color-warn); }
  .ui-bg-color-warn { background-color: var(--gw-color-warn); }
  .ui-border-color-warn { border-color: var(--gw-color-warn); }

  .ui-color-success { color: var(--gw-color-success); }
  .ui-bg-color-success { background-color: var(--gw-color-success); }
  .ui-border-color-success { border-color: var(--gw-color-success); }

  .ui-color-gold { color: var(--gw-color-gold); }
  .ui-bg-color-gold { background-color: var(--gw-color-gold); }
  .ui-border-color-gold { border-color: var(--gw-color-gold); }

  .ui-color-white { color: var(--gw-color-white); }
  .ui-bg-color-white { background-color: var(--gw-color-white); }
  .ui-border-color-white { border-color: var(--gw-color-white); }

  .ui-color-black { color: var(--gw-color-black); }
  .ui-bg-color-black { background-color: var(--gw-color-black); }
  .ui-border-color-black { border-color: var(--gw-color-black); }

  .ui-color-alpha { color: var(--gw-color-alpha); }
  .ui-bg-color-alpha { background-color: var(--gw-color-alpha); }
  .ui-border-color-alpha { border-color: var(--gw-color-alpha); }

  .ui-color-bravo { color: var(--gw-color-bravo); }
  .ui-bg-color-bravo { background-color: var(--gw-color-bravo); }
  .ui-border-color-bravo { border-color: var(--gw-color-bravo); }

  .ui-color-medium { color: var(--gw-color-medium); }
  .ui-bg-color-medium { background-color: var(--gw-color-medium); }
  .ui-border-color-medium { border-color: var(--gw-color-medium); }

  .ui-color-charlie { color: var(--gw-color-charlie); }
  .ui-bg-color-charlie { background-color: var(--gw-color-charlie); }
  .ui-border-color-charlie { border-color: var(--gw-color-charlie); }

  .ui-color-card { color: var(--gw-color-card); }
  .ui-bg-color-card { background-color: var(--gw-color-card); }
  .ui-border-color-card { border-color: var(--gw-color-card); }

  /* MUI */
  .MuiFormControl-root {
    width: 100%;
  }
  .MuiTextField-root {
    width: 100%;
    border-radius: 3px;
    overflow: hidden;
  }
  .MuiFilledInput-root,
  .MuiFilledInput-multiline,
  input {
    background-color: var(--gw-color-alpha)!important;
  }
  .MuiFilledInput-underline:before {
    border-color: var(--gw-color-alpha)!important;
  }
  .MuiInputLabel-root {
    font-size: var(--gw-font-small);
    color: var(--gw-color-charlie) !important;
    width: 100%;
  }
  .MuiInputLabel-filled.MuiInputLabel-shrink {
    transform: translate(12px, var(--gw-item-size-0)) scale(.8);
    font-size: var(--gw-color-charlie);
  }
`;

export interface ApplicationThemeProviderProps {
  children: ReactNode;
}

const Provider: React.FC<ApplicationThemeProviderProps> = ({ children }) => {
  const { activeAppSize, activeThemeMode, activeThemeName } = useApplicationTheme();

  const themeColors = THEME_MODE_COLORS[activeThemeMode];

  const appTheme: ApplicationTheme = {
    ...themeColors.colors,
    currentThemeColor: THEME_COLORS[activeThemeName],
    currentThemeColorHover: '',
    font: FONT_SIZES[activeAppSize],
    icon: ICON_SIZES[activeAppSize],
    textTheme:
      activeThemeMode === 'light' ? THEME_MODE_COLORS.light.colors.alpha : themeColors.colors.alpha,
    activeThemeMode,
  };

  const muiTheme = createTheme({
    palette: {
      mode: appTheme.currentThemeColor === 'light' ? 'light' : 'dark',
      primary: {
        main: appTheme.currentThemeColor,
      },
      background: {
        default: appTheme.bravo,
      },
      text: {
        primary: appTheme.charlie,
      },
    },
    typography: {
      fontFamily: 'Montserrat, sans-serif',
      fontSize: parseInt(appTheme.font.small, 10), // Using base 10 for parsing
    },
    components: {
      MuiButton: {
        styleOverrides: {
          textPrimary: appTheme.charlie,
          root: appTheme.charlie,
          containedPrimary: appTheme.charlie,
        },
      },
      MuiTooltip: {
        styleOverrides: {
          popper: {
            zIndex: 99999,
          },
          tooltip: {
            backgroundColor: appTheme.medium,
            zIndex: 99999,
          },
        },
      },
      MuiPopover: {
        styleOverrides: {
          root: {
            zIndex: 99999,
          },
          paper: {
            backgroundColor: appTheme.bravo,
          },
        },
      },
    },
  });

  return (
    <StyledThemeProvider theme={appTheme}>
      <GlobalStyle />
      <MuiThemeProvider theme={muiTheme}>{children}</MuiThemeProvider>
    </StyledThemeProvider>
  );
};

export default Provider;
