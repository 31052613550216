import styled from 'styled-components';

export default styled.div`
  .main-screen-container {
    height: 100vh;
  }
  main {
    height: max-content;
  }
`;
