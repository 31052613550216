import React, { FC } from 'react';
import { Topic } from '@mui/icons-material';
import EmptyContentWrapper from './empty-content.styled';

export interface EmptyContentProps {}

const EmptyContent: FC<EmptyContentProps> = () => (
  <EmptyContentWrapper
    className="ui-display-flex ui-items-center ui-justify-center ui-flex-column"
    data-testid="EmptyContent"
  >
    <div className="ui-bg-color-card ui-radius-circle mb6 empty-content-svg-container ui-display-flex ui-items-center ui-justify-center">
      <Topic className="ui-color-medium" />
    </div>
    <span className="ui-text-center ui-font-normal ui-color-charlie ui-opacity-disabled">
      Oops, the database is snoozing.
      <br />
      No items to show at the moment.
      <br />
      Stay tuned!
    </span>
  </EmptyContentWrapper>
);

export default EmptyContent;
