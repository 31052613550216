import React, { FC, useEffect, useState } from 'react';
import { EmptyContent, Loader, MainScreen } from 'components';
import ArchivePageWrapper from './page.styled';

interface ArchivePageProps {}

const ArchivePage: FC<ArchivePageProps> = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const randomDelay = Math.random() * (2500 - 1000) + 1000; // Random delay between 1 to 2.5 seconds

    const timer = setTimeout(() => {
      setIsLoading(false);
    }, randomDelay);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <ArchivePageWrapper data-test-archive-page-wrapper>
      <MainScreen
        title="Archive"
        description="Complete section of projects I've developed or significantly contributed to."
      >
        <div className="mt9 pt9">
          {isLoading ? <Loader ellipsis color="theme" /> : <EmptyContent />}
        </div>
      </MainScreen>
    </ArchivePageWrapper>
  );
};

export default ArchivePage;
