import React, { FC } from 'react';
import { KeyboardBackspace } from '@mui/icons-material';
import { Button, ButtonProps } from '@mui/material';
import { Link } from 'react-router-dom';
import HeaderBarWrapper from './header-bar.styled';

export interface HeaderBarProps extends ButtonProps {
  title: string;
  buttonText?: string;
  backToPath?: string;
}

const HeaderBar: FC<HeaderBarProps> = (props) => {
  const { title, buttonText, backToPath, ...buttonProps } = props || {
    title: undefined,
  };

  return (
    <HeaderBarWrapper className="ph5 modal-header-container ui-display-flex ui-items-center ui-justify-between ui-bg-color-bravo">
      <div className="ui-display-flex">
        {backToPath && (
          <div className="mr5">
            <Link
              to={backToPath}
              className="ui-display-flex ui-items-center ui-justify-center"
              rel="noopener noreferrer"
              aria-label="Navigate back"
              data-test-main-screen-nav-link="home"
            >
              <KeyboardBackspace className="ui-color-charlie" />
            </Link>
          </div>
        )}
        {title && (
          <h1 className="ui-font-large ui-display-flex ui-items-center ui-justify-center ui-color-charlie">
            {title}
          </h1>
        )}
      </div>
      <Button variant="text" {...buttonProps}>
        {buttonText}
      </Button>
    </HeaderBarWrapper>
  );
};

export default HeaderBar;
