import { ApplicationThemeState } from 'redux-store/application-theme';

export type ThemeColor = 'mosiac' | 'verdant' | 'ignis' | 'champion' | 'amethyst' | 'fiery';
export type ThemeMode = 'light' | 'dim' | 'dark';
export type CustomThemeMode = 'ambient' | 'dim' | 'midnight';
export type ModeColor = 'alpha' | 'bravo' | 'medium' | 'charlie' | 'card';
export type GlobalColor = 'error' | 'red' | 'warn' | 'success' | 'gold' | 'white' | 'black';
export type AppSize = 'smallest' | 'small' | 'regular' | 'large' | 'largest';
export type AppSizeItem = 'xsmall' | 'small' | 'normal' | 'large' | 'xlarge' | 'jumbo' | 'xjumbo';
export type AppColor =
  | 'alpha'
  | 'bravo'
  | 'medium'
  | 'charlie'
  | 'theme'
  | 'card'
  | 'error'
  | 'red'
  | 'warn'
  | 'success'
  | 'gold'
  | 'white'
  | 'black';

export interface ApplicationTheme extends Record<ModeColor, string> {
  currentThemeColor: string;
  currentThemeColorHover: string;
  font: AppSizeItemConfig;
  icon: AppSizeItemConfig;
  textTheme: string;
  activeThemeMode: ThemeMode;
}

/**
 * Interface for application mode colors.
 */
interface ThemeModeColors {
  name: CustomThemeMode;
  colors: Record<ModeColor, string>;
}

/**
 * Interface for app size item configuration.
 */
interface AppSizeItemConfig {
  xsmall: string;
  small: string;
  normal: string;
  large: string;
  xlarge: string;
  jumbo: string;
  xjumbo: string;
}

/**
 * Default application theme redux state.
 */
export const DEFAULT_APPLICATION_THEME_STATE: ApplicationThemeState = Object.freeze({
  mode: 'dim',
  color: 'mosiac',
  size: 'regular',
  isSystemDefault: false,
});

/**
 * Application theme colors.
 */
export const THEME_COLORS: Record<ThemeColor, string> = Object.freeze({
  mosiac: 'rgb(45, 140, 119)',
  verdant: 'rgb(13, 120, 68)',
  ignis: 'rgb(190, 98, 43)',
  champion: 'rgb(164, 114, 0)',
  amethyst: 'rgb(127, 76, 220)',
  fiery: 'rgb(180, 10, 90)',
});

/**
 * Application theme array.
 */
export const THEME_COLOR_ARRAY: ThemeColor[] = [
  'mosiac',
  'verdant',
  'ignis',
  'champion',
  'amethyst',
  'fiery',
];

/**
 * Application mode colors.
 */
export const THEME_MODE_COLORS: Record<ThemeMode, ThemeModeColors> = Object.freeze({
  light: {
    name: 'ambient',
    colors: {
      alpha: 'rgb(230, 220, 210)',
      bravo: 'rgb(200, 190, 180)',
      medium: 'rgb(100, 100, 100)',
      charlie: 'rgb(0, 0, 0)',
      card: 'rgb(170, 160, 150)',
    },
  },
  dim: {
    name: 'dim',
    colors: {
      alpha: 'rgb(15, 25, 35)',
      bravo: 'rgb(40, 50, 60)',
      medium: 'rgb(155, 165, 175)',
      charlie: 'rgb(255, 255, 255)',
      card: 'rgb(30, 40, 50)',
    },
  },
  dark: {
    name: 'midnight',
    colors: {
      alpha: 'rgb(0, 0, 0)',
      bravo: 'rgb(28, 28, 28)',
      medium: 'rgb(137, 137, 137)',
      charlie: 'rgb(225, 225, 225)',
      card: 'rgb(15, 15, 15)',
    },
  },
});

/**
 * Application mode array.
 */
export const THEME_MODE_ARRAY: ThemeMode[] = ['light', 'dim', 'dark'];

/**
 * Application global colors.
 */
export const GLOBAL_COLORS: Record<GlobalColor, string> = Object.freeze({
  error: 'rgb(199,0,57)',
  red: 'rgb(255,0,0)',
  warn: 'rgb(255,204,0)',
  success: 'rgb(75,181,67)',
  gold: 'rgb(212,175,55)',
  white: THEME_MODE_COLORS.light.colors.alpha,
  black: THEME_MODE_COLORS.dark.colors.alpha,
});

/**
 * Application size array.
 */
export const APP_SIZE_ARRAY: AppSize[] = ['smallest', 'small', 'regular', 'large', 'largest'];

/**
 * Application font size configuration.
 */
export const FONT_SIZES: Record<AppSize, AppSizeItemConfig> = Object.freeze({
  smallest: {
    xsmall: '10px',
    small: '12px',
    normal: '14px',
    large: '16px',
    xlarge: '18px',
    jumbo: '20px',
    xjumbo: '28px',
  },
  small: {
    xsmall: '12px',
    small: '14px',
    normal: '16px',
    large: '18px',
    xlarge: '20px',
    jumbo: '24px',
    xjumbo: '52px',
  },
  regular: {
    xsmall: '14px',
    small: '16px',
    normal: '18px',
    large: '20px',
    xlarge: '22px',
    jumbo: '26px',
    xjumbo: '54px',
  },
  large: {
    xsmall: '16px',
    small: '18px',
    normal: '20px',
    large: '22px',
    xlarge: '24px',
    jumbo: '28px',
    xjumbo: '56px',
  },
  largest: {
    xsmall: '18px',
    small: '20px',
    normal: '22px',
    large: '24px',
    xlarge: '26px',
    jumbo: '30px',
    xjumbo: '58px',
  },
});

/**
 * Application icon size configuration.
 */
export const ICON_SIZES: Record<AppSize, AppSizeItemConfig> = Object.freeze({
  smallest: {
    xsmall: '12px',
    small: '16px',
    normal: '20px',
    large: '24px',
    xlarge: '28px',
    jumbo: '36px',
    xjumbo: '44px',
  },
  small: {
    xsmall: '14px',
    small: '18px',
    normal: '22px',
    large: '26px',
    xlarge: '30px',
    jumbo: '38px',
    xjumbo: '46px',
  },
  regular: {
    xsmall: '16px',
    small: '20px',
    normal: '24px',
    large: '28px',
    xlarge: '32px',
    jumbo: '40px',
    xjumbo: '48px',
  },
  large: {
    xsmall: '18px',
    small: '22px',
    normal: '26px',
    large: '30px',
    xlarge: '34px',
    jumbo: '42px',
    xjumbo: '50px',
  },
  largest: {
    xsmall: '20px',
    small: '24px',
    normal: '28px',
    large: '32px',
    xlarge: '36px',
    jumbo: '44px',
    xjumbo: '52px',
  },
});
