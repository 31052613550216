/**
 * Checks if an object is empty (has no own enumerable properties).
 * @param {any} object - The object to check.
 * @param {boolean} [checkValues] - Optional. If set to true, checks if the keys have a non-undefined value.
 * @returns Returns `true` if the object is empty, `false` otherwise.
 */
export const isEmptyObject = (object: any, checkValues: boolean = false) => {
  if (checkValues) {
    const keys = Object.keys(object);
    return keys.every((key) => object[key] === undefined || object[key] === null);
  }

  return Object.keys(object).length === 0;
};

/**
 * Delays the execution for the specified number of milliseconds.
 *
 * @param {number} milliseconds - The number of milliseconds to delay.
 * @returns A promise that resolves after the delay.
 */
export const delay = (milliseconds: number) => {
  return new Promise<void>((resolve) => {
    setTimeout(() => {
      resolve();
    }, milliseconds);
  });
};

/**
 * Get the time of day based on the current hour.
 * @param [currentTime] - Optional. The current date.
 * @returns The time of day: 'morning', 'afternoon', or 'night'.
 */
export const getTimeOfDay = (currentTime = new Date()) => {
  const currentHour = currentTime.getHours();

  if (currentHour >= 5 && currentHour < 12) {
    return 'morning';
  }
  if (currentHour >= 12 && currentHour < 18) {
    return 'afternoon';
  }
  return 'night';
};

/**
 * Darkens an RGB color represented as a string.
 *
 * @param {string} rgb - The RGB color string in the format "rgb(r, g, b)".
 * @param {number} percentage - The percentage by which to darken the color (between 0 and 100).
 * @returns {string} The darkened RGB color as a string in the format "rgb(r, g, b)".
 * @throws {Error} If the input is not a valid RGB color string or if the percentage is out of range.
 */
export const darkenRGB = (rgb: string, percentage: number): string => {
  if (percentage < 0 || percentage > 100) {
    throw new Error('Percentage should be between 0 and 100.');
  }

  // Extract the RGB values from the string
  const match = rgb.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);

  if (!match) {
    throw new Error('Invalid RGB color string.');
  }

  const [, red, green, blue] = match.map(Number);

  // Darken the RGB values
  const darkenedRed = Math.max(0, red - (red * percentage) / 100);
  const darkenedGreen = Math.max(0, green - (green * percentage) / 100);
  const darkenedBlue = Math.max(0, blue - (blue * percentage) / 100);

  // Return the darkened RGB color as a string
  return `rgb(${Math.round(darkenedRed)}, ${Math.round(darkenedGreen)}, ${Math.round(
    darkenedBlue,
  )})`;
};
