import { ROUTE_NAME_MAP } from 'constants/routes';
import { useOnlineStatus } from 'hooks';
import { ArchivePage, ContactPage, HomePage, ModalPage, NotFoundPage } from 'pages';
import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

const MODAL_ROUTE_PATH = ROUTE_NAME_MAP.modal();

const App: React.FC = () => {
  useOnlineStatus();
  const location = useLocation();
  const backgroundPath = location.state?.background;
  const isBackgroundPath = !!backgroundPath;

  return (
    <>
      <Routes location={isBackgroundPath ? backgroundPath : location}>
        <Route path={ROUTE_NAME_MAP.HOME_INDEX} index element={<HomePage />} />
        <Route path={ROUTE_NAME_MAP.ARCHIVE_INDEX} element={<ArchivePage />} />
        <Route path={ROUTE_NAME_MAP.CONTACT_INDEX} element={<ContactPage />} />
        <Route path={MODAL_ROUTE_PATH} element={!isBackgroundPath ? <ModalPage /> : <div />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
      {isBackgroundPath && (
        <Routes>
          <Route path={MODAL_ROUTE_PATH} element={<ModalPage />} />
        </Routes>
      )}
    </>
  );
};

export default App;
