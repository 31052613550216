import styled from 'styled-components';

export default styled.div`
  .empty-content-svg-container {
    width: calc(var(--gw-icon-xjumbo) * 2.5);
    height: calc(var(--gw-icon-xjumbo) * 2.5);
  }
  svg {
    width: 60%;
    height: 60%;
  }
`;
