import React, { FC } from 'react';
import { ModalProps } from '@mui/material';
import { HeaderBar, HeaderBarProps } from 'components';
import ModalContainerWrapper from './modal-container.styled';

export interface ModalContainerProps extends Omit<ModalProps, 'children'> {
  type: 'center' | 'left' | 'right' | 'bottom' | 'sub';
  headerProps?: HeaderBarProps;
  children?: React.ReactNode;
}

const ModalContainer: FC<ModalContainerProps> = (props) => {
  const { type, headerProps, children, ...rest } = props;
  let modalContentClassName = `modal-container-content modal-container-content-${type} ui-color-charlie`;
  let component = <div />;

  switch (type) {
    case 'center':
    case 'left':
    case 'right':
      modalContentClassName += ' ui-bg-color-bravo';
      component = <div className={modalContentClassName}>{children}</div>;
      break;
    case 'bottom':
      modalContentClassName += ' ui-display-flex ui-items-center ui-justify-center';
      component = (
        <div className={modalContentClassName}>
          <div className="bottom-modal-container ui-bg-color-bravo">
            {headerProps && <HeaderBar {...headerProps} />}
            <div className="bottom-modal-content">{children}</div>
          </div>
        </div>
      );
      break;
    case 'sub':
      modalContentClassName += ' ui-bg-color-bravo';
      component = (
        <div className={modalContentClassName}>
          <div className="sub-modal-container ui-display-flex">
            {headerProps && <HeaderBar {...headerProps} />}
            <div className="sub-modal-content">{children}</div>
          </div>
        </div>
      );
      break;
    default:
      break;
  }

  return (
    <ModalContainerWrapper
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      data-testid="ModalContainer"
      disablePortal
      type={type}
      {...rest}
    >
      {component}
    </ModalContainerWrapper>
  );
};

export default ModalContainer;
